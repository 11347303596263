import { Options, Vue } from 'vue-class-component';
import { mapGetters } from 'vuex';

import ModalCharging from '@/components/ModalChargingComponent/ModalCharging.vue';
import Task from '@/model/Classes/Task';
import { User } from '@/model/Classes/User';
import CreateTaskDTO from '@/model/DTOs/CreateTaskDTO';
import CreateStageDTO from '@/model/DTOs/CreateStageDTO';
import UpdateStageDTO from '@/model/DTOs/UpdateStageDTO';
import UpdateProductDTO from '@/model/DTOs/UpdateProductDTO';
import { BaseProductDTO } from '@/model/DTOs/CreateBaseProductDTO';
import { Product } from '@/model/Classes/Product';
import { Stage } from '@/model/Classes/Stage';
import ViewTask from '@/model/Classes/ViewTask';
import { ProductsService } from '@/services/ProductsService';
import { StagesService } from '@/services/StagesService';
import { TasksService } from '@/services/TasksService';
import utils from '@/utils/utils';
import ModalComponent from '../ModalComponent/ModalComponent.vue';
import TaskItem from '../TaskItem/TaskItem.vue';
import TaskModal from '../TaskModal/TaskModal.vue';

@Options({
  name: 'ProductDetail',
  components: {
    // HeaderProjectDescription,
    ModalComponent,
    ModalCharging,
    TaskItem,
    TaskModal,
  },
  props: {
    progress: {
      type: Number,
      required: false,
      default: 50,
    },
    productPicked: {
      type: Object as () => Product,
      required: false,
    },
    stageSelected: {
      type: Object as () => Stage,
      default: undefined,
    },
    context: {
      type: String,
      default: 'stage',
      validator(value: string) {
        // The value must match one of these strings
        return ['stage', 'product'].includes(value);
      },
    },
    stages: {
      type: Object as () => Stage[],
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    numberOfProducts: {
      type: Number,
      required: true,
    },
    usersOnProject: {
      type: Object as () => User[],
      required: true,
    },
    isLastStage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      testTasks: [],
      openModal: false,
      product: {
        id: '',
        name: '',
        state: '',
        description: '',
        startDate: '',
        endDate: '',
      },
      stage: {
        id: '',
        name: '',
        description: '',
        progress: 0,
        startDate: '',
        endDate: '',
        state: '',
      },
      newProduct: {
        id: '',
        name: '',
        state: '',
        description: '',
        startDate: '',
        endDate: '',
      },
      newStage: {
        id: '',
        name: '',
        description: '',
        progress: 1,
        startDate: '',
        endDate: '',
        state: '',
        order: 0,
      },
      editSessionOn: false,
      stagesAndTasks: [],
      newStageEdit: false,
      newProductEdit: false,
      tasks: [],
      addTaskActive: false,
      newTask: {
        name: '',
        description: 'Descripción de la tarea',
        state: '0',
        stage_id: '',
        parent_task_id: '',
      },
      taskOpened: undefined,
      tasksToToggle: [],
      availablePositionsArray: [],
      isLoading: false,
    };
  },
  watch: {
    stageSelected(newVal: Stage) {
      this.editSessionOn = false;
      this.stage.id = newVal.id;
      this.stage.name = newVal.name;
      this.stage.progress = newVal.progress;
      this.stage.startDate = newVal.startDate;
      this.stage.endDate = newVal.endDate;
      this.stage.state = newVal.state;
      this.getTasksFromStage(newVal.id);
      this.generateAvailablePositionsArray();
      this.cancelAddTask();
    },
    productPicked(newVal: Product) {
      this.editSessionOn = false;
      this.product.id = newVal.id;
      this.product.name = newVal.name;
      this.product.state = newVal.state;
      this.product.description = newVal.description;
      this.product.startDate = newVal.startDate;
      this.product.endDate = newVal.endDate;
      this.product.progress = newVal.progress;
    },
  },
  computed: {
    ...mapGetters('user', ['isUserAdmin', 'isUserProducer', 'getUserAsUser']),
    handleTaskToggle() {
      return !this.editSessionOn;
    },
    disableInput() {
      return !this.editSessionOn;
    },
  },
  methods: {
    visibleToThisUser(taskId: string) {
      let visible = false;
      const taskFound = this.tasks.find((task: Task) => task.id === taskId);
      taskFound.visibleUserIds?.forEach((view:ViewTask) => {
        if (view.userId === this.getUserAsUser.id) {
          visible = true;
        }
      });
      return visible;
    },
    async getTasksFromStage(stageId: string) {
      try {
        this.isLoading = true;
        const response = await StagesService.getStageById(stageId);
        const tasksIds = [];
        const driveFolderId = response.data.folder_drive_id;
        this.stage.progress = response.data.progress;
        this.tasks = response.data.tasks.map((task: any) => new Task({
          id: task.task_id,
          state: task.state,
          name: task.name,
          description: task.description,
          creationDate: task.created_at,
          deliverDate: task.deliver_date,
          folderDriveId: driveFolderId,
          isPublic: task.public,
          visibleUserIds: task.view_tasks.map((taskView: any) => new ViewTask({
            viewTaskId: taskView.view_task_id,
            userProjectId: taskView.user_project.user_project_id,
            userId: taskView.user_project.user.user_id,
          })),
          subTasks: task.child_tasks ? task.child_tasks.map((subTask: any) => new Task({
            id: subTask.task_id,
            state: subTask.state,
            name: subTask.name,
            description: subTask.description,
            creationDate: subTask.created_at,
            deliverDate: task.deliver_date,
            folderDriveId: driveFolderId,
            isPublic: subTask.public,
            // visibleUserIds: subTask.view_tasks.map((taskView: any) => {
            //   return new ViewTask({
            //     viewTaskId: taskView.view_task_id,
            //     userProjectId: taskView.user_project.user_project_id,
            //     userId: taskView.user_project.user.user_id,
            //   });
            // }),
          })) : [],
        }));
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error obteniendo tareas del stage: ${error}`);
      }
    },
    cancelEdition() {
      this.newStageEdit = false;
      this.newProductEdit = false;
      this.editSessionOn = false;
      this.cleanNewStage();
      this.cleanNewProduct();
    },
    // Stage Section
    addStage() {
      this.editSessionOn = true;
      this.newStageEdit = true;
    },
    editStageData() {
      this.editSessionOn = true;
    },
    updateStage() {
      this.isLoading = true;
      const updatedStage = new UpdateStageDTO({
        name: this.stage.name ? this.stage.name : undefined,
        description: this.stage.description ? this.stage.description : undefined,
        state: this.stage.state ? this.stage.state : undefined,
        start_date: this.stage.startDate ? this.stage.startDate : undefined,
        end_date: this.stage.endDate ? this.stage.endDate : undefined,
      });
      this.saveStageData(updatedStage);
    },
    async saveStageData(body: UpdateStageDTO) {
      try {
        const response = await StagesService.updateStage(this.stage.id, body);
        this.$emit('stage-updated');
        this.editSessionOn = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al actualizar el stage: ${error}`);
      }
    },
    async saveNewStage() {
      try {
        this.isLoading = true;
        const response = await StagesService.createStage(new CreateStageDTO(
          {
            name: this.newStage.name,
            progress: this.newStage.progress,
            state: this.newStage.state,
            product_id: this.productPicked.id,
            description: this.newStage.description,
            order: this.newStage.order,
            start_date: this.newStage.startDate,
            end_date: this.newStage.endDate ? this.newStage.endDate : null,
          },
        ));
        this.$emit('new-stage-created');
        this.newStageEdit = false;
        this.editSessionOn = false;
        this.cleanNewStage();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al crear el nuevo stage: ${error}`);
      }
    },
    cleanNewStage() {
      this.newStage.id = '';
      this.newStage.name = '';
      this.newStage.description = '';
      this.newStage.progress = 0;
      this.newStage.startDate = '';
      this.newStage.endDate = '';
      this.newStage.state = '';
      this.newStage.order = 0;
    },
    generateAvailablePositionsArray() {
      this.availablePositionsArray = [];
      for (let index = 0; index <= this.productPicked.stagesNumer; index += 1) {
        this.availablePositionsArray.push(index + 1);
      }
    },
    async deleteStage() {
      try {
        this.isLoading = true;
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm('Al eliminar la etapa, no se eliminarán los datos de drive, pero no se podrá recuperar la informaición de este, ¿Estás seguro de que quieres eliminar esta etapa?');
        if (answer && this.numberOfProducts > 1) {
          await StagesService.deleteById(this.stage.id);
          alert('Etapa eliminada con éxito');
          this.$emit('stage-deleted');
          this.isLoading = false;
        } else if (this.numberOfProducts === 1) {
          this.isLoading = false;
          alert('No se puede eliminar la unica etapa del proyecto');
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error al eliminar la etapa: ${error}`);
      }
    },
    // Product Section
    addNewProduct() {
      this.editSessionOn = true;
      this.newProductEdit = true;
    },
    editProjectData() {
      this.editSessionOn = true;
    },
    async saveProduct() {
      this.editSessionOn = false;
      this.cleanNewProduct();
    },
    async saveNewProduct() {
      try {
        this.isLoading = true;
        const newProduct = BaseProductDTO;
        newProduct.product.name = this.newProduct.name;
        newProduct.product.state = this.newProduct.state;
        newProduct.product.description = this.newProduct.description;
        newProduct.product.start_date = this.newProduct.startDate;
        newProduct.product.project_id = this.projectId;
        const response = await ProductsService.createBaseProduct(newProduct);
        this.newProductEdit = false;
        this.editSessionOn = false;
        this.cleanNewProduct();
        this.$emit('new-product-created');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al crear el nuevo producto: ${error}`);
      }
    },
    cleanNewProduct() {
      this.newProduct.id = '';
      this.newProduct.name = '';
      this.newProduct.state = '';
      this.newProduct.description = '';
      this.newProduct.startDate = '';
      this.newProduct.endDate = '';
    },
    updateProduct() {
      const updatedProduct = new UpdateProductDTO({
        name: this.product.name ? this.product.name : undefined,
        description: this.product.description ? this.product.description : undefined,
        state: this.product.state ? this.product.state : undefined,
        start_date: this.product.startDate ? this.product.startDate : undefined,
        end_date: this.product.endDate ? this.product.endDate : undefined,
      });
      this.saveProductData(updatedProduct);
    },
    async saveProductData(body: UpdateProductDTO) {
      try {
        this.isLoading = true;
        const response = await ProductsService.updateProduct(this.product.id, body);
        this.$emit('product-updated');
        this.editSessionOn = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al actualizar el producto: ${error}`);
      }
    },
    async deleteProduct() {
      try {
        this.isLoading = true;
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm('Al eliminar el producto, no se eliminarán los datos de drive, pero no se podrá recuperar la informaición de este, ¿Estás seguro de que quieres eliminar este producto?');
        if (answer && this.numberOfProducts > 1) {
          await ProductsService.deleteById(this.product.id);
          alert('Producto elminado con éxito');
          this.$emit('product-deleted');
          this.isLoading = false;
        } else if (this.numberOfProducts === 1) {
          this.isLoading = false;
          alert('No se puede eliminar el único producto del proyecto');
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error al eliminar el producto: ${error}`);
      }
    },
    // Task Section
    addTask() {
      // if (this.stageSelected.order === this.stages.length && this.tasks.length >= 1) {
      //   alert('No se puede agregar mas de una tarea a la última etapa');
      // } else {
      // }
      this.addTaskActive = true;
      this.newTask.name = '';
      this.newTask.stage_id = '';
      this.newTask.parent_task_id = '';
    },
    cancelAddTask() {
      this.addTaskActive = false;
      this.newTask.name = '';
      this.newTask.stage_id = '';
      this.newTask.parent_task_id = '';
    },
    async createNewTask() {
      try {
        this.isLoading = true;
        const newTask = new CreateTaskDTO({
          name: this.newTask.name,
          description: this.newTask.description,
          state: this.newTask.state,
          stage_id: this.stage.id,
        });
        const response = await TasksService.createTask(newTask);
        this.newTask.name = '';
        this.newTask.stage_id = '';
        this.newTask.parent_task_id = '';
        this.getTasksFromStage(this.stage.id);
        this.isLoading = false;
      } catch (error) {
        this.cancelAddTask();
        this.isLoading = false;
        alert(`Error al crear la tarea: ${error}`);
      }
    },
    async createNewSubTask(subTaskData: CreateTaskDTO) {
      try {
        this.isLoading = true;
        const response = await TasksService.createTask(subTaskData);
        this.getTasksFromStage(this.stage.id);
        this.isLoading = false;
      } catch (error) {
        this.cancelAddTask();
        this.isLoading = false;
        alert(`Error al crear la sub tarea: ${error}`);
      }
    },
    manageTasksToToggle(taskId: string) {
      if (this.tasksToToggle.includes(taskId)) {
        const index = this.tasksToToggle.findIndex((taskIdInner: string) => taskIdInner === taskId);
        this.tasksToToggle.splice(index, 1);
      } else {
        this.tasksToToggle.push(taskId);
      }
    },
    shouldToggle(taskId: string) {
      return this.tasksToToggle.includes(taskId);
    },
    refreshStageData() {
      this.getTasksFromStage(this.stage.id);
    },
    formatState(state: string) {
      return utils.returnStateVerbose(state);
    },
    formatUTCDate(datetoFormat: string) {
      return utils.formatUTCDate(datetoFormat);
    },
    goToLastStage() {
      this.$router.push({
        name: 'last-stage',
        params: {
          projectId: this.projectId,
          productId: this.product.id,
        },
      });
    },
    getShareStageLink() {
      const stageLink = `${window.location.origin}/public-stage/${this.stageSelected.id}`;
      // Copy the text inside the text field
      navigator.clipboard.writeText(stageLink);

      // Alert the copied text
      alert(`Copiado el link: ${stageLink}`);
    },
  },
})

export default class ProductDetail extends Vue {}
