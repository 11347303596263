import { Options, Vue } from 'vue-class-component';
import { mapState, mapGetters, mapActions } from 'vuex';

import { CreateBaseProjectDTO, BaseProjectDTO } from '@/model/DTOs/CreateBaseProjectDTO';
import { Project } from '@/model/Classes/Project';
import { ProjectsService } from '@/services/ProjectsService';
import { CategoriesService } from '@/services/CategoriesService';
import CategoryDTO from '@/model/DTOs/CategoryDTO';
import { User, UsersArray } from '@/model/Classes/User';
import UserDTO from '@/model/DTOs/UserDTO';
import UpdateProjectDTO from '@/model/DTOs/UpdateProjectDTO';
import utils from '@/utils/utils';
import ModalCharging from '../ModalChargingComponent/ModalCharging.vue';

import UserProfilePicker from '../UserProfilePicker/UserProfilePicker.vue';

@Options({

  name: 'GalleryCardDescription',
  components: {
    UserProfilePicker,
    ModalCharging,
  },
  props: {
    creatingProject: {
      type: Boolean,
      required: true,
    },
    projectPicked: {
      type: Object as () => Project | undefined,
      required: false,
    },
    usersOnProject: {
      type: Array as () => User[],
      required: false,
    },
  },
  data() {
    return {
      editSessionOn: false,
      usersPerProject: [],
      users: [] as User[],
      project: {
        id: '',
        name: '',
        description: '',
        startDate: '',
        endDate: '',
        state: '',
        progress: 0,
        category_id: '',
        enterprise_id: '',
      },
      pickedEnterpriseFromAdmin: '',
      pickedUserToAdd: '',
      isLoading: false,
      imgFile: undefined,
      newCategory: '',
    };
  },
  mounted() {
    // this.fillUsers();
  },
  computed: {
    ...mapState('applicationData', ['categories', 'usersFromEnterprise', 'enterprises']),
    ...mapState('user', ['uniqueEnterprises', 'loggedUser']),
    ...mapGetters('user', ['isUserAdmin']),
    disableInput() {
      return !this.editSessionOn;
    },
  },
  watch: {
    projectPicked(newVal, oldVal) { // watch it
      this.cancelEdition();
      this.users = [];
      if (this.projectPicked !== undefined) {
        this.getUsersFromEnterprise(newVal.enterpriseId);
        this.getProjectData(newVal.id);
        this.project.id = newVal.id;
        this.project.name = newVal.name;
        this.project.description = newVal.description;
        this.project.startDate = newVal.startDate;
        this.project.endDate = newVal.endDate;
        this.project.state = newVal.state;
        this.project.category_id = newVal.category;
        this.project.enterprise_id = newVal.enterpriseId;
        this.project.progress = newVal.progress;
      } else {
        if (this.creatingProject) {
          this.activeEdition();
        }
        this.project.name = '';
        this.project.description = '';
        this.project.startDate = '';
        this.project.endDate = '';
        this.project.state = '';
        this.project.category_id = '';
        this.project.enterprise_id = '';
        this.project.progress = 0;
      }
    },
  },
  methods: {
    ...mapActions('user', ['updateProjects']),
    ...mapActions('applicationData', ['getUsersFromEnterprise', 'getRegisteredCategories']),
    async createNewProject() {
      this.isLoading = true;
      try {
        const newBaseProject = BaseProjectDTO;
        newBaseProject.project.name = this.project.name;
        newBaseProject.project.description = this.project.description;
        newBaseProject.project.start_date = this.project.startDate
          ? this.project.startDate : undefined;
        newBaseProject.project.end_date = this.project.endDate ? this.project.endDate : undefined;
        newBaseProject.project.state = this.project.state;
        newBaseProject.project.category_id = this.project.category_id;
        if (this.isUserAdmin) {
          newBaseProject.project.enterprise_id = this.pickedEnterpriseFromAdmin;
        } else {
          newBaseProject.project.enterprise_id = this.loggedUser.enterprise.enterprise_id;
        }
        // add users to the project
        newBaseProject.users = this.users.map((user: User) => ({ user_id: user.id }));
        const newProject = await ProjectsService.createBaseProject(newBaseProject);
        this.saveImageForProject(newProject.data.project_id);
        this.isLoading = false;
        this.cancelEdition();
        this.$emit('project-created');
      } catch (error) {
        this.isLoading = false;
        this.cancelEdition();
        console.error(`Error al crear el proyecto: ${error}`);
        alert(`Error al crear el proyecto: ${error}`);
      }
    },
    activeEdition() {
      this.editSessionOn = true;
    },
    cancelEdition() {
      this.editSessionOn = false;
    },
    openProject() {
      this.$router.push({ name: 'products', params: { projectId: this.projectPicked.id } });
    },
    editProject() {
      this.activeEdition();
    },
    saveProject() {
      if (this.editSessionOn && this.creatingProject) {
        this.createNewProject();
      } else {
        this.updateProject();
      }
    },
    addUserToProject() {
      try {
        const userAdded = this.usersFromEnterprise
          .find((user: any) => user.user_id === this.pickedUserToAdd);
        const fullName = `${userAdded.first_name} ${userAdded.last_name}`;
        const userIds = this.users.map((user: User) => user.id);
        if (!userIds.includes(this.pickedUserToAdd)) {
          this.users.push(new User({
            id: userAdded.user_id,
            name: fullName,
            imgUrl: userAdded.img_url,
            profile: userAdded.profile_type,
            headline: userAdded.profesional_headline,
          }));
          if (!this.creatingProject) {
            this.isLoading = true;
            this.addUserToProjectOnEndpoint(this.pickedUserToAdd);
          }
        } else if (userIds.includes(this.pickedUserToAdd)) {
          this.isLoading = false;
          alert(`El usuario ${fullName} ya fue asignado al proyecto`);
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error al añadir el usuario al proyecto: ${error}`);
      }
    },
    async addUserToProjectOnEndpoint(userId: string) {
      try {
        await ProjectsService.addSingleUserToProject({
          project_id: this.project.id,
          user_id: userId,
        });
        this.isLoading = false;
      } catch (error) {
        this.getProjectData(this.project.id);
        this.isLoading = false;
        alert(`Error al añadir el usuario al proyecto: ${error}`);
      }
    },
    removeUserFromProject(userId: string) {
      try {
        this.isLoading = true;
        const index = this.users.findIndex((user: User) => user.id === userId);
        this.users.splice(index, 1);
        if (!this.creatingProject) {
          this.removeUserToProjectOnEndpoint(userId);
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error al eliminar el usuario del proyecto: ${error}`);
      }
    },
    async removeUserToProjectOnEndpoint(userId: string) {
      try {
        await ProjectsService.removeSingleUserFromProject(this.project.id, userId);
        this.getProjectData(this.project.id);
        this.isLoading = false;
      } catch (error) {
        this.getProjectData(this.project.id);
        this.isLoading = false;
        alert(`Error al eliminar al usuario al proyecto: ${error}`);
      }
    },
    async getProjectData(projectId: string) {
      try {
        this.isLoading = true;
        const response = await ProjectsService.getProjectDetailById(projectId);
        const usersOnProject = response.data.users.map((user: any) => user.user);
        this.usersPerProject = usersOnProject;
        this.prepareDisplayUsersOnProject();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al obtener datos del proyecto: ${error}`);
      }
    },
    prepareDisplayUsersOnProject() {
      this.users = this.usersPerProject
        .map((userOnProject: any) => {
          const fullName = `${userOnProject.first_name} ${userOnProject.last_name}`;
          return new User({
            id: userOnProject.user_id,
            name: userOnProject.first_name,
            imgUrl: userOnProject.img_url,
            profile: userOnProject.profile_type,
            // TODO add profile type to the user its not getting it from the back
            headline: userOnProject.profesional_headline,
          });
        });
    },
    saveImageForProject(projectId: string) {
      this.isLoading = true;
      const newProjectImage = new FormData();
      newProjectImage.append('img_url', this.imgFile);
      this.createProjectImage(projectId, newProjectImage);
    },
    async createProjectImage(projectId: string, newProjectImage: any) {
      try {
        const imageCreated = await ProjectsService.updateProjectImage(projectId, newProjectImage);
        alert('Imagen actualizada');
        this.$emit('project-updated');
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert('Error guardando la imagen del proyecto');
      }
    },
    prepareFile(event: any) {
      const index = 0;
      this.imgFile = event.target.files[index];
      if (!this.creatingProject) {
        this.saveImageForProject(this.project.id);
      }
    },
    closeDescriptionContainer() {
      this.$emit('close-description-container');
    },
    enterpriseChanged() {
      this.users = [];
      this.getUsersFromEnterprise(this.pickedEnterpriseFromAdmin);
    },
    updateProject() {
      this.isLoading = true;
      const updatedProject = new UpdateProjectDTO({
        name: this.project.name ? this.project.name : undefined,
        description: this.project.description ? this.project.description : undefined,
        state: this.project.state ? this.project.state : undefined,
        start_date: this.project.startDate ? this.project.startDate : undefined,
        end_date: this.project.endDate ? this.project.endDate : undefined,
        category_id: this.project.category_id ? this.project.category_id : undefined,
      });
      this.saveProjectData(updatedProject);
    },
    async saveProjectData(body: UpdateProjectDTO) {
      try {
        const response = await ProjectsService.updateProject(this.project.id, body);
        this.$emit('project-updated');
        this.editSessionOn = false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        alert(`Error al actualizar el proyecto: ${error}`);
      }
    },
    checkIfCategoryExists(categoryToCheck: string) {
      let exists = false;
      this.categories.forEach((category: CategoryDTO) => {
        if (category.name.toLowerCase() === categoryToCheck.toLowerCase()) {
          exists = true;
        }
      });
      return exists;
    },
    getCategoryName(categoryIdToCheck: string) {
      const categoryFound = this.categories
        .find((category: CategoryDTO) => category.category_id === categoryIdToCheck);
      return categoryFound ? categoryFound.name : '';
    },
    async addNewCategory() {
      try {
        if (this.checkIfCategoryExists(this.newCategory)) {
          alert('Esta categoría ya existe');
        } else {
          this.isLoading = true;
          await CategoriesService.addNewCategory({ name: this.newCategory });
          this.getRegisteredCategories();
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        alert(`Error al crear la categoría: ${error}`);
      }
    },
    translateState(state: string) {
      return utils.returnStateVerbose(state);
    },
  },
})

export default class GalleryCardDescription extends Vue {}
