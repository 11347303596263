import { Options, Vue } from 'vue-class-component';

import HeaderApp from '@/components/HeaderApp/HeaderApp.vue';
import ModalComponent from '@/components/ModalComponent/ModalComponent.vue';
import { ProjectsService } from '@/services/ProjectsService';
import ProductDTO from '@/model/DTOs/ProductDTO';
import StageDTO from '@/model/DTOs/StageDTO';
import { Product } from '@/model/Classes/Product';
import { ProductsService } from '@/services/ProductsService';
// to import StartRating needed to include index.d.ts in node_modules/vue-star-rating
// along with the following line declare module 'vue-star-rating';
import StarRating from 'vue-star-rating';

@Options({
  name: 'LastStageView',
  components: {
    HeaderApp,
    ModalComponent,
    StarRating,
    // Vue3StarRatings,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      documents: ['Documento 1', 'Documento 2', 'Documento 3', 'Documento 4'],
      projectData: null,
      productSelected: null,
      /* eslint-disable */
      moutainImage: require('@/assets/images/generic_mountain.png'),
      /* eslint-enable */
      isCompleted: true,
      openSurvey: false,
      surveyScore: 0,
      surveyFeedback: '',
      displayedName: '',
      displayedDescription: '',
      displayedInformation: '',
      projectDocuments: [],
      productDocuments: [],
      products: [],
      firstProductPicked: '',
    };
  },
  mounted() {
    this.getAllProjectData();
  },
  methods: {
    openSurveyModal() {
      this.openSurvey = true;
    },
    closeSurveyModal() {
      this.openSurvey = false;
    },
    updatedRating() {
      // TODO: send rating to backend
      // console.log('rating', this.surveyScore);
    },
    async getAllProjectData() {
      try {
        const result = await ProjectsService.getFinalProjectDetailById(this.projectId);
        this.projectData = result.data;
        // this.prepareHeaderData();
        if (this.productId !== '0') {
          this.prepareProductData();
        } else {
          this.prepareProjectData();
        }
      } catch (error) {
        alert('Error al obtener los datos del producto');
      }
    },
    prepareHeaderData() {
      this.products = this.projectData.products.map((product: any) => new Product({
        id: product.product_id,
        name: product.name,
        state: product.state,
        description: product.description,
      }));
    },
    prepareProjectData() {
      this.displayedName = this.projectData.name;
      this.displayedDescription = this.projectData.description;
      const productsNames = this.projectData.products.map((product: any) => product.name);
      this.displayedInformation = `Productos en el proyecto: ${productsNames.join(', ')}`;
      // this.projectDocuments = this.projectData.documents;
    },
    prepareProductData() {
      this.productSelected = this.projectData.products
        .find((product: ProductDTO) => product.product_id === this.productId);
      this.displayedName = this.productSelected.name;
      this.displayedDescription = this.productSelected.description;
      const lastStageOrder = this.productSelected.stages.length;
      const lastStage = this.productSelected.stages
        .find((stage: StageDTO) => stage.order === lastStageOrder);
      const documentsOnLastStage: any[] = [];
      lastStage.tasks.forEach((task: any) => {
        task.documents.forEach((document: any) => {
          documentsOnLastStage.push(document);
        });
      });
      this.productDocuments = documentsOnLastStage.map((document: any) => ({
        name: document.name,
        url: document.url_file,
      }));
    },
    getShareProductLink() {
      const stageLink = `${window.location.origin}/public-stage/${this.projectId}/${this.productId}`;
      // Copy the text inside the text field
      navigator.clipboard.writeText(stageLink);
      // Alert the copied text
      alert(`Copiado el link: ${stageLink}`);
    },
    async saveProductSurvey() {
      try {
        const updateData = {
          calification: this.surveyScore,
          comments: this.surveyFeedback,
        };
        await ProductsService.updateProduct(this.projectId, updateData);
        alert('Gracias por tu retroalimentación');
      } catch (error) {
        alert(`Error agregando la calificación: ${error}`);
      }
    },
  },
})

export default class LastStageView extends Vue {}
